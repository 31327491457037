<template>
    <div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12">

                <div class="row col-12">
                    <div class="card gutter-b">
                        <div class="card-header">
                            <div class="card-title">
                                <h3 class="card-label">{{ customer.first_name }} {{ customer.last_name }} ({{
                                    customer.unique_id }}) 
                                <span v-if="customer.kwc_required == 0" class="badge bagde-sm badge-success"> VERIFIED </span> 
                                <span v-if="customer.kwc_required == 1" class="badge bagde-sm badge-warning"> UNVERIFIED </span>    
                                </h3>
                            </div>
                        </div>
                        <div class="card-body body-fluid">
                            <div class="row">
                                <div class="col-6">
                                    <span><b>Email</b></span>
                                </div>
                                <div class="col-6">
                                    {{ customer.email }}
                                </div>
                                <div class="col-6">
                                    <span><b>Account Number</b></span>
                                </div>
                                <div class="col-6">
                                    {{ customer.unique_id }}
                                </div>

                                <div class="col-6">
                                    <span><b>Billing Address</b></span>
                                </div>
                                <div class="col-6">
                                    {{ customer.address }} {{ customer.city }} {{ customer.state_name }}, {{
                                        customer.postal_code }} {{ customer.country_name }}
                                </div>

                                <div class="col-6">
                                    <span><b>Phone No.</b></span>
                                </div>
                                <div class="col-6">
                                    {{ customer.country_code_phone }}{{ customer.phone }}
                                </div>

                                <div class="col-6">
                                    <span><b>Account Created Date</b></span>
                                </div>
                                <div class="col-6">
                                    {{ customer.created_at }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-lg-7 col-md-7 col-sm-12">
                <div class="row col-12">
                    <div class="card card-custom gutter-b w-100">
                        <div class="card-header">
                            <div class="card-title">
                                <h3 class="card-label">Supporting Document</h3>
                            </div>
                            <div class="card-toolbar">
                                <b-button variant="outline-primary mr-3" v-if="customer.kwc_required == 1" v-b-modal.manual-verification-modal>Verify Account</b-button>
                                <b-button variant="outline-primary" v-b-modal.add-supporting-modal>Attach
                                    Document</b-button>
                            </div>
                        </div>
                        <div class="card-body body-fluid">
                            <div class="table-responsive">
                                <table class="table table-hover table-stripped table-borderless">
                                    <thead>
                                        <tr>
                                            <th>Document</th>
                                            <th>Type</th>
                                            <th>Action</th>
                                            <th>Reason</th>
                                        </tr>
                                    </thead>
                                    <template v-for="(item, i) in docs">
                                        <tbody>
                                            <tr v-bind:key="i">
                                                <td> <a href="" @click="viewDoc(item)">Supporting Document</a> </td>
                                                <td>
                                                    <span v-if="item.file_type == 'nid'"> Others</span>
                                                    <span v-if="item.file_type == 'ubill'"> Utility Bill</span>
                                                </td>
                                                <td> <button class="btn btn-sm mr-3" tooltip="Accept"
                                                        v-if="item.accepted == 0 && item.is_active == 1"
                                                        @click="acceptSpdoc(item)"><i class="flaticon2-checkmark"
                                                            style="color: #339509;"></i></button>
                                                    <button class="btn btn-sm" tooltip="Reject"
                                                        v-if="item.accepted == 0 && item.is_active == 1"
                                                        @click="showRejectionPopup(item)"><i class="flaticon2-cross"
                                                            style="color: #d71414;"></i></button>

                                                    <div v-if="item.accepted == 1">
                                                        <i class="flaticon2-checkmark" style="color: #339509;"></i>
                                                    </div>
                                                    <div v-if="item.accepted == 0">
                                                        <i class="flaticon2-cross" style="color: #d71414;"></i>
                                                    </div>
                                                </td>
                                                <td v-if="item.is_active == 0 && item.accepted == 0" class="text-danger">
                                                    {{ item.rejection_reason }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-12">
                <div class="card w-100 gutter-b">
                    <div class="card-header">
                        <div class="card-title">
                            <h3 class="card-label">Total Shipments</h3>
                        </div>
                    </div>
                    <div class="card-body body-fluid">
                        <div class="table-responsive">
                            <b-table id="shipments-tbl" striped hover :items="shipments" :fields="fields">

                                <template #cell(payment_status)="row">
                                    <template v-if="row.item.payment_status == 1">
                                        <span class="badge badge-pill badge-info">Paid</span>
                                    </template>
                                    <template v-else>
                                        <span class="badge badge-pill badge-warning">Due</span>
                                    </template>
                                </template>
                                <template #cell(tracking_no)="row">
                                    <a class="a" @click="getRecord(row.item.id)">{{
                                        row.item.tracking_no
                                    }}</a>
                                </template>
                            </b-table>
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right"
                                aria-controls="shipments-tbl"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card w-100 gutter-b">
                    <div class="card-header">
                        <div class="card-title">
                            <h3 class="card-label">Account Summary</h3>
                        </div>
                    </div>
                    <div class="card-body body-fluid">
                        <apexchart class="card-rounded-bottom" :options="chartOptions" :series="series" type="area"
                            width="100%">
                        </apexchart>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card w-100  gutter-b">
                    <div class="card-header">
                        <div class="card-title">
                            <h3 class="card-label">Address Book</h3>
                        </div>
                    </div>
                    <div class="card-body body-fluid">
                        <div class="table-responsive">
                            <b-table id="shipments-tbl" striped hover :items="addressBook" :fields="addressFields">

                            </b-table>
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right"
                                aria-controls="shipments-tbl"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="rejection-reason-modal" ref="modal" size="lg" @hidden="resetModal" @ok="rejectSpdoc"
            title="Rejection Reason">
            <form ref="editform" @submit.stop.prevent="handleSubmit">
                <div class="row">
                    <div class="col-md-8">
                        <b-form-group label="Rejection Reason">
                            <b-form-input v-model="rejection_reason"></b-form-input>
                        </b-form-group>
                    </div>

                </div>
            </form>
        </b-modal>

        <b-modal id="acceptence-modal" ref="modal" size="md" @hidden="resetModal" @ok="acceptSpdoc"
            title="Rejection Reason">
            <form ref="editform" @submit.stop.prevent="handleSubmit">
                <div class="row">
                    <div class="col">
                        Are you sure to accept and verify the supporting document
                    </div>

                </div>
            </form>
        </b-modal>


        <b-modal id="add-supporting-modal" ref="modal" size="xl" @hidden="resetModalS" @ok="saveSupportingDoc"
            title="Attach Supporting Document">
            <form ref="addsupportingdoc" @submit.stop.prevent="handleSubmit">
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label class="font-weight-bold">Select Document Type</label>
                            <select class="form-control" v-model="file_type">
                                <option value="0" selected disabled> -- Type -- </option>
                                <option value="nid"> National ID Card </option>
                                <option value="ubill"> Utility Bill</option>
                            </select>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label class="font-weight-bold">Upload File</label>
                            <b-form-file accept=".jpg, .png, .pdf" plain class="form-control" @change="onFileChange($event)"
                                v-model="file"></b-form-file>
                            <label class="font-weight-bold">Supported Files: (JPG,PNG and PDF)</label>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group mt-10">
                            <b-form-checkbox id="checkbox-1" v-model="preApproved" name="checkbox-1" value="1"
                                unchecked-value="0">
                                Verify & approve the customer account
                            </b-form-checkbox>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>

        <b-modal id="manual-verification-modal" ref="modal" size="md" @hidden="rejectManualVerification"
            @ok="acceptManualVerification" title="Attach Supporting Document">
            <form ref="addsupportingdoc" @submit.stop.prevent="handleSubmit">
                <div class="row">
                    <div class="col">
                        <p>
                            Make sure that all the required supporting documents are reviewd carefully and are accepted. 
                        </p>
                    </div>
                </div>
            </form>
        </b-modal>
        <loading :active.sync="isLoading"></loading>

    </div>
</template>
<style>
.card-title {
    margin-bottom: 0rem !important;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Loading from "vue-loading-overlay";

export default {
    data() {
        return {
            id: this.$route.params.id,
            customer: null,
            shipments: null,
            summary: null,
            addressBook: null,
            docs: null,
            file_type: "0",
            rejection_reason: "",
            doc_id: null,
            isLoading: false,
            fields: [
                {
                    key: "tracking_no",
                    label: "Tracking No.",
                },

                {
                    key: "status",
                    label: "Status",
                },

                {
                    key: "receiver_city",
                    label: "Origin To Destination",
                    formatter: (value, key, item) => {

                        if (item.type == 1) {
                            return item.prealert_sender_city + " To " + value;
                        }
                        if (item.type == 2) {
                            return item.sender_city + " To " + value;
                        }
                    },
                },

                {
                    key: "shipping_mode",
                    label: "Mode",
                },

                {
                    key: "purchase_date",
                    label: "Booking Date",
                    formatter: (value, key, item) => {
                        return new Date(item.booking_date).toLocaleDateString();
                    },
                },
                {
                    key: "payment_status",
                    label: "Invoice Status",
                },

            ],
            addressFields: [
                "name",
                "phone",
                "email",
                "street_address",
                "city",
                "country",
                "state",
                "zip",
                "actions",
            ],
            chartOptions: {},
            series: [],
            file: null,
            preApproved: 0,
            currentPage: null,
            rows: null,
            perPage: null,
            baseUrl: process.env.VUE_APP_FILE_URL,
        };
    },
    components: {
        Loading,
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Customer's Dashboard" }]);
        this.pageLoad();
    },
    methods: {
        pageLoad() {
            this.isLoading = true;
            ApiService.get("cusDashData", this.id)
                .then((response) => {
                    if (response.data.success) {
                        this.customer = response.data.customer;
                        this.shipments = response.data.shipments;
                        this.summary = response.data.summary;
                        this.addressBook = response.data.addressBook;
                        this.docs = response.data.docs;
                        this.loadChart(response.data.summary);
                    }
                    this.isLoading = false;
                })
                .catch(({ response }) => {
                    this.isLoading = false;
                });
        },
        loadChart(data) {
            this.series.push({
                name: "Net Sales",
                data: data.trend,
            });
            this.chartOptions = {
                chart: {
                    type: "area",
                    height: 150,
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                    sparkline: {
                        enabled: true,
                    },
                },
                plotOptions: {},
                legend: {
                    show: false,
                },
                dataLabels: {
                    enabled: false,
                },
                fill: {
                    type: "solid",
                    opacity: 1,
                },
                stroke: {
                    curve: "smooth",
                    show: true,
                    width: 3,
                    colors: [this.layoutConfig("colors.theme.base.success")],
                },
                xaxis: {
                    categories: data.months,
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: true,
                    },
                    labels: {
                        show: true,
                        style: {
                            colors: this.layoutConfig("colors.gray.gray-500"),
                            fontSize: "12px",
                            fontFamily: this.layoutConfig("font-family"),
                        },
                    },
                    crosshairs: {
                        show: false,
                        position: "front",
                        stroke: {
                            color: this.layoutConfig("colors.gray.gray-300"),
                            width: 1,
                            dashArray: 3,
                        },
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                yaxis: {
                    min: 0,
                    max: 55,
                    show: false,
                    labels: {
                        show: false,
                        style: {
                            colors: this.layoutConfig("colors.gray.gray-500"),
                            fontSize: "12px",
                            fontFamily: this.layoutConfig("font-family"),
                        },
                    },
                },
                states: {
                    normal: {
                        filter: {
                            type: "none",
                            value: 0,
                        },
                    },
                    hover: {
                        filter: {
                            type: "none",
                            value: 0,
                        },
                    },
                    active: {
                        allowMultipleDataPointsSelection: false,
                        filter: {
                            type: "none",
                            value: 0,
                        },
                    },
                },
                tooltip: {
                    style: {
                        fontSize: "12px",
                        fontFamily: this.layoutConfig("font-family"),
                    },
                    y: {
                        formatter: function (val) {
                            // return "$" + val + " thousands";
                            return val;
                        },
                    },
                },
                colors: [this.layoutConfig("colors.theme.light.success")],
                markers: {
                    colors: [this.layoutConfig("colors.theme.light.success")],
                    strokeColor: [this.layoutConfig("colors.theme.base.success")],
                    strokeWidth: 3,
                },
                grid: {
                    show: false,
                    padding: {
                        left: 0,
                        right: 0,
                    },
                },
            };
        },
        makeToastVariant(variant = null, text, title) {
            this.$bvToast.toast(text, {
                title: title,
                variant: variant,
                solid: true,
            });
        },
        showFile(item) {
            if (item != "") {
                window.open(this.baseUrl + item, "_blank");
            }
        },
        getRecord(id) {
            this.$router.push({
                name: "view-shipment",
                params: {
                    id: id,
                },
            });
        },
        viewDoc(item) {
            window.open(this.baseUrl + item.file_path, "_blank");
        },
        showRejectionPopup(item) {
            this.doc_id = item.id;
            this.$bvModal.show("rejection-reason-modal");
        },
        showAcceptPopup(item) {
            this.doc_id = item.id;
            this.$bvModal.show("acceptence-modal");
        },
        acceptSpdoc(item) {
            ApiService.get("acceptSupportingDoc", item.id)
                .then(() => {
                    this.pageLoad();
                })
                .catch(({ response }) => {
                    this.isLoading = false;
                    for (let field of Object.keys(response.data.error)) {
                        this.makeToastVariant(
                            "danger",
                            response.data.error[field][0],
                            "Error"
                        );
                    }
                });
        },
        rejectSpdoc(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.isLoading = true;
            ApiService.update("rejectSupportingDoc", this.doc_id, { rejection_reason: this.rejection_reason })
                .then(() => {
                    this.pageLoad();
                    this.$bvModal.hide("rejection-reason-modal");
                })
                .catch(({ response }) => {
                    this.isLoading = false;
                    for (let field of Object.keys(response.data.error)) {
                        this.makeToastVariant(
                            "danger",
                            response.data.error[field][0],
                            "Error"
                        );
                    }
                });
        },
        onFileChange(e) {
            this.file = e.target.files[0];
        },
        resetModal() {

        },
        resetModalS() {
            this.file = null;
            this.$bvModal.hide("add-supporting-modal");
        },
        saveSupportingDoc() {
            debugger;
            if (this.file_type != "0" && this.file != null) {
                this.isLoading = true;
                let formData = new FormData();
                formData.append("file", this.file);
                formData.append("file_type", this.file_type);
                formData.append("user_id", this.id);
                formData.append("verification_status", this.preApproved);

                ApiService.upload("saveSupportingDocumentAdmin", formData)
                    .then((response) => {
                        if (response.data.success) {
                            // this.file_path = response.data.file
                            this.getSupportingDoc();
                            this.isLoading = false;
                            this.makeToastVariant(
                                "success",
                                "Supporting Document attached successfuly with profile",
                                "Success"
                            );
                        } else {
                            this.isLoading = false;
                            this.makeToastVariant(
                                "danger",
                                response.data.message,
                                "Error"
                            );
                        }
                    })
                    .catch(({ response }) => {
                        this.isLoading = false;
                        for (let field of Object.keys(response.data.error)) {
                            this.makeToastVariant(
                                "danger",
                                response.data.error[field][0],
                                "Error"
                            );
                        }
                    });
            } else {
                if (this.file == null) {
                    this.makeToastVariant(
                        "danger",
                        "Select the document to upload",
                        "Error"
                    );
                } else if (this.file_type == "0") {
                    this.makeToastVariant(
                        "danger",
                        "Select the document type",
                        "Error"
                    );
                } else {
                    this.makeToastVariant(
                        "danger",
                        "Select the  Document type & document to upload",
                        "Error"
                    );
                }

            }
        },
        getSupportingDoc() {
            ApiService.get("getSupportingDocument", this.id)
                .then((response) => {
                    if (response.data.success) {
                        this.docs = response.data.list;
                    }
                })
                .catch(({ response }) => { });
        },
        acceptManualVerification(){
            ApiService.get("manualVerification", this.id)
                .then((response) => {
                    if (response.data.success) {
                        this.makeToastVariant(
                        "success",
                        response.data.message,
                        "Success"
                    );
                    this.customer = response.data.user;
                    this.$bvModal.hide("manual-verification-modal");
                    }else{
                        this.makeToastVariant(
                        "danger",
                        response.data.message,
                        "Error");
                    }
                    
                })
                .catch(({ response }) => { });
        },
        rejectManualVerification(){
            this.$bvModal.hide("manual-verification-modal");
        }
    }
};
</script>
  